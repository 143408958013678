<template>
  <div>
    <el-form label-position="top" v-if="orderData">
      <el-row>
        <el-col :span="20" :offset="2">
          <!-- Бүх хуудсанд ашиглагдах header section -->
          <custom-header title="Захиалгийн мэдээлэл"/>
          <el-steps :space="200" :active="activeStep" finish-status="success" align-center  style="justify-content: center" class="mb20">
            <el-step title="Үлдэгдэл шалгах"></el-step>
            <el-step title="Жолоочид хуваарилах"></el-step>
            <el-step title="Жолоочид хуваарилсан"></el-step>
            <el-step title="Хүргэлтэнд гарсан"></el-step>
            <el-step title="Очсон"></el-step>
            <el-step title="Амжилттай"></el-step>
          </el-steps>
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <header>Сагс</header>
                  <ul class="transfer-product-list">
                    <el-row>
                      <el-col :span="18">
                        <strong>Захиалгын дугаар:{{ orderData.order_number}}</strong>
                      </el-col>
                      <el-col :span="6">
                        <el-button @click="generate" type="primary" icon="el-icon-printer">Хэвлэх</el-button>
                      </el-col>
                    </el-row>
                    <div v-for="(product, productIndex) in orderData.OrderItems" :key="productIndex">
                      <el-row :gutter="10" class="mb20" style="background-color: orange; padding: 10px; border-radius: 6px" type="flex" align="middle" v-if="product.is_discount" >
                        <el-col :span="8">
                          <div>
                            {{ product.product_name + " - " + product.variant_name}}
                          </div>
                          <div class="text-color-secondary">
                            {{product.sku}}
                          </div>
                          <div class="text-color-secondary">
                            {{product.supplier_sku}}
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left">{{product.supplier_name}}</el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="12">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left"> = {{generateCurrency(product.menu_price * product.item_count)}}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" style="padding: 10px" type="flex" align="middle" v-else >
                        <el-col :span="8">
                          <div>
                            {{ product.variant_name === 'default' ? product.product_name : product.product_name + " - " + product.variant_name}}
                          </div>
                          <div class="text-color-secondary">
                            SKU: {{product.sku}}
                          </div>
                          <div class="text-color-secondary">
                            Харилцагчийн SKU: {{product.supplier_sku}}
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left">{{product.supplier_name}}</el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="12">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-right">{{generateCurrency(product.menu_price)}} x  {{parseInt(product.item_count)}}</el-col>
                            <el-col :span="8" class="text-left"> = {{generateCurrency(product.menu_price * product.item_count)}}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>
                  </ul>
                  <header v-if="orderData.OrderDiscounts && orderData.OrderDiscounts.length !== 0">Хөнгөлөлт</header>
                  <ul class="transfer-product-list" v-if="orderData.OrderDiscounts && orderData.OrderDiscounts.length !== 0">
                    <div v-for="(discount, discountIndex) in orderData.OrderDiscounts" :key="discountIndex">
                      <el-row :gutter="10" class="mb20" style="padding: 10px" type="flex" align="middle">
                        <el-col :span="8">
                          <div>{{discount.discount_title_mon}}</div>
                          <!-- <div class="text-color-secondary" style="margin-top:5px; margin-bottom:5px">{{ generateCouponType(discount.discount_type) }}</div> -->
                          <b v-if="discount.discount_type === 'PERCENTAGE'">Хөнгөлөлт - {{ discount.discount.saled_percentage }} %</b>
                          <b v-else-if="discount.discount_type === 'FIXED_AMOUNT'">Хөнгөлөлт - {{ generateCurrency(discount.discount.saled_price) }}</b>
                          <div>Купон код: {{ discount.discount_code }}</div>
                        </el-col>
                      </el-row>
                    </div>
                  </ul>
                </div>
                <div class="panel-item">
                  <header>Захиалгын дэлгэрэнгүй</header>
                  <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="3" v-if="activeStep === 2">
                      <el-tag>{{orderData.order_status}}</el-tag>
                    </el-col>
                    <el-col :span="3"  v-if="activeStep === 3">
                      <el-tag>{{orderData.order_status}}</el-tag>
                    </el-col>
                    <el-col :span="3"  v-if="activeStep >= 4">
                      <el-tag>{{orderData.order_status}}</el-tag>
                    </el-col>
                    <el-col :span="5" v-if="orderData.createdAt !== null && orderData.createdAt !== ''">
                      <strong> Захиалсан огноо </strong> <br> {{orderData.createdAt}}
                    </el-col>
                    <el-col :span="5" v-if="orderData.promised_time !== null && orderData.promised_time !== ''">
                      <strong> Хүргэх огноо </strong> <br> {{orderData.promised_time}}
                    </el-col>
                    <el-col :span="6" v-if="orderData.delivered_at !== null && orderData.delivered_at !== ''">
                      <strong> Хүргэсэн огноо </strong> <br> {{generateDate(orderData.delivered_at)}}
                    </el-col>
                    <el-col :span="5" >
                      <el-button :disabled="isDisabled(activeStep)" v-if="activeStep <= 5" @click="nextStep" :loading="isLoadingButton" type="warning" >Төлөв ахиулах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Захиалгын тэмдэглэл" class="mb20">
                        <el-input v-model="orderData.note" type="textarea" rows="3" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-row :gutter="10" class="mb10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Орж ирсэн суваг
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div style="color: red;"><strong>{{orderData.channel}}</strong></div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Барааны нийт үнэ
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{orderData.menu_total}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хүргэлтийн төлбөр
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{orderData.delivery_charge === '0.00' ? orderData.delivery_charge + '₮' : generateCurrency(orderData.delivery_charge)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb10" v-if="orderData.delivery_fee && parseInt(orderData.delivery_fee) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Бага дүнтэй захиалгын хураамж
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.delivery_fee)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb10" v-if="orderData.discount_total && parseInt(orderData.discount_total) !== 0 && (orderData.pay_total_no_discount - orderData.pay_total ) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хөнгөлөлтгүй дүн
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.pay_total_no_discount)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb10" v-if="orderData.discount_total && parseInt(orderData.discount_total) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хөнгөлөлтийн дүн
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.discount_total)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb10" v-if="orderData.vat_type">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            НӨАТ
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{orderData.vat_type == 1 ? 'Хувь хүн' : orderData.vat_type == 3 ? orderData.vat_company_name + ' ' + orderData.vat_regno : ''}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb5">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong> Төлөх дүн </strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>
                            <strong>{{generateCurrency(orderData.pay_total)}}</strong>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" v-if="paymentOrder !== null" class="mb5">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong> Төлбөрийн хэрэгсэл </strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>
                            <strong>{{ paymentOrder.payment_name }}</strong>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong> Төлбөрийн статус </strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div style="color: red;" v-if="orderData.pay_status === 'Төлөөгүй'">
                            <strong>{{orderData.pay_status}}</strong>
                          </div>
                          <div style="color: green;" v-else>
                            <strong>{{orderData.pay_status}}</strong>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </div>
               <span slot="footer" class="dialog-footer">
        <el-button @click="orderCancel = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/order">
          <el-button type="danger" @click="orderCancel = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
              <!-- Order cancel button -->
                <el-button v-if="this.orderData.order_status === 'Цуцалсан'" type='warning' disabled>ЦУЦЛАГДСАН</el-button>
                <el-button v-else-if="orderData.order_status !== 'Амжилттай' && orderData.group_order_id === null || (orderData.group_order_id !== null && orderData.pay_status === 'Төлөөгүй' && orderData.order_status !== 'Амжилттай')" type="danger" @click="orderCancel = true">Цуцлах</el-button>
              <div class="panel mt20" v-if="activeStep !== 0">
                <div class="panel-item">
                  <header>Жолооч</header>
                </div>
                <div class="panel-item">
                  <!-- <header>Жолооч сонгоно уу!</header>-->
                  <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="12">
                      <el-select v-model="selectedDriver" placeholder="Жолооч сонгоно уу!">
                        <el-option
                          v-for="(item, indexRider) in riders"
                          :key="indexRider"
                          :label="item.Username"
                          :value="item.Username">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="success" @click="assignDriver()">Жолоочид хуваарилах</el-button>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Хэрэглэгч</header>
                  <el-form-item label="Утас">
                    <el-input v-model="orderData.user_phone" type="number" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Email">
                    <el-input v-model="orderData.user_email" type="email" :disabled="true"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Хүргэлтийн хаяг</header>
                  <el-form-item label="Хаяг">
                    <el-input v-model="orderData.full_address" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Орц">
                    <el-input v-model="orderData.addr_orts" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Хаалга">
                    <el-input v-model="orderData.addr_haalga" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Орцны код">
                    <el-input v-model="orderData.addr_orts_code" :disabled="true" ></el-input>
                  </el-form-item>
                  <el-form-item label="Нэмэлт">
                    <el-input v-model="orderData.addr_desc" type="textarea" :rows="3" :disabled="true"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="closeOrderdetail">Буцах</el-button>
            <el-button v-if="orderData.pay_status !== 'Төлөөгүй'" type="success" @click="completeOrder">Дуусгах</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- Order cancel dialog -->
      <el-dialog
      title=" Захиалга цуцлах"
      :visible.sync="orderCancel"
      v-loading.fullscreen.lock="deleteLoading">
      <el-form ref="cancelOrderForm" :model="cancelOrderForm" label-width="120px">
        <el-form-item label="Төрөл" prop="cancelStatus" :rules="[{ required: true, message: 'Та төрөл сонгоно уу?'}]">
          <el-select v-model="cancelOrderForm.cancelStatus" placeholder="Төрөл сонгох">
            <el-option
              v-for="(cancel, cancelIndex) in cancelOrderType"
              :key="cancelIndex"
              :label="cancel.label"
              :value="cancel.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Тайлбар:">
          <el-input type="textarea" placeholder="Тайлбар бичих" v-model="cancelOrderForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="defult" @click="orderCancel = false">Буцах</el-button>
        <el-button class="ml10" type="danger" @click="orderDelete('cancelOrderForm')">Цуцлах</el-button>
      </span>
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>

<script>

import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
import customHeader from '../../../components/common/customHeader'
import loader from '../../../components/common/loader'
import { getUserName } from '../../../utils/auth'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
// import { getName } from '../../../utils/auth'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'OrderDetail',
  components: {
    customHeader: customHeader,
    loader: loader
  },

  mounted () {
    if (this.$route.params.id) {
      this.getOrderData(this.$route.params.id)
    }
    this.getRiderUser()
    getUserName().then(email => {
      this.userName = email.split('@')[0]
    })
  },

  data () {
    return {
      deleteLoading: false,
      cancelOrderForm: {
        cancelStatus: '',
        desc: ''
      },
      reason: '',
      orderCancel: false,
      activeStep: 0,
      orderData: null,
      isLoading: false,
      isLoadingButton: false,
      riders: [],
      selectedDriver: null,
      userName: '',
      paymentOrder: null,
      cancelOrderType: [{
        value: 'Харилцагчаас шалтгаалсан',
        label: 'Харилцагчаас шалтгаалсан'
      }, {
        value: 'Хэрэглэгчээс шалтгаалсан',
        label: 'Хэрэглэгчээс шалтгаалсан'
      }, {
        value: 'Үйл ажиллагаанаас шалтгаалсан',
        label: 'Үйл ажиллагаанаас шалтгаалсан'
      }, {
        value: 'Системээс шалтгаалсан',
        label: 'Системээс шалтгаалсан'
      }, {
        value: 'Тест',
        label: 'Тест'
      }],
      rules: {
        status: [
          { required: true, message: 'Та төрлөө сонгоно уу?', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    generate () {
      const docDefinition = {
        content: [
          { text: 'Хүргэлтийн хаяг: ' + (this.orderData.full_address) },
          { text: 'Орц: ' + (this.orderData.addr_orts) },
          { text: 'Орцны код: ' + (this.orderData.addr_orts_code) },
          { text: 'Хаалга: ' + (this.orderData.addr_haalga) },
          { text: 'Хэрэглэгчийн утасны дугаар: ' + (this.orderData.user_phone), alignment: 'right' },
          { text: 'И-мэйл хаяг: ' + (this.orderData.user_email), alignment: 'right' },
          { text: 'Захиалгын дугаар: ' + (this.orderData.order_number) },
          {
            table: {
              body: [
                [{ text: 'Бүтээгдэхүүн', style: 'tableHeader' }, { text: 'Barcode', style: 'tableHeader' }, { text: 'SKU', style: 'tableHeader' }, { text: 'Барааны үнэ', style: 'tableHeader' }, { text: 'Тоо ширхэг', style: 'tableHeader' }, { text: 'Нийт үнэ', style: 'tableHeader' }]
              ]
            }
          },
          ('\n'),
          { text: 'Нийт ширхэг: ' + (this.orderData.totalItemCount), alignment: 'right' },
          { text: 'Нийт барааны үнэ: ' + (this.orderData.menu_total), alignment: 'right' },
          { text: 'Хүргэлтийн төлбөр: ' + (this.orderData.delivery_charge), alignment: 'right' },
          { text: 'Нийт үнэ: ' + (this.orderData.pay_total), alignment: 'right' },
          { style: 'date', alignment: 'center', margin: [0, 30, 0, 0], text: 'Хэвлэсэн огноо: ' + (new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) }
        ],
        styles: {
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 10]
          },
          title: {
            fontSize: 18,
            bold: true,
            margin: [-10, 0, 0, 10]
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          status: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 5, 15]
          }
        }
      }
      this.orderData.OrderItems.forEach(item => {
        docDefinition.content[7].table.body.push([{ text: item.product_name + (item.variant_name === 'default' ? ' ' : '-' + item.variant_name) }, { text: ' ' }, { text: item.sku }, { text: item.menu_price }, { text: item.item_count }, { text: item.menu_price * item.item_count }])
      })
      pdfMake.createPdf(docDefinition).print()
    },
    isDisabled (step) {
      let isDisabled = false
      if (step === 1) {
        if (!this.selectedDriver) {
          isDisabled = true
        }
      }
      if (step === 5) {
        isDisabled = true
      }
      if (this.orderData.order_status === 'Цуцалсан') {
        isDisabled = true
      }
      return isDisabled
    },
    calculateOrderStatus (status) {
      let returnStatus = 0
      switch (status) {
        case 'Үлдэгдэл шалгах':
          returnStatus = 0
          break
        case 'Үлдэгдэл шалгасан':
          returnStatus = 1
          break
        case 'Жолоочид хуваарилах':
          returnStatus = 1
          break
        case 'Жолоочид хуваарилсан':
          returnStatus = 2
          break
        case 'Хүргэлтэнд гарсан':
          returnStatus = 3
          break
        case 'Очсон':
          returnStatus = 4
          break
        case 'Амжилттай':
          returnStatus = 6
          break
        default:
          break
      }
      this.activeStep = returnStatus
    },
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    generateDate (datetime) {
      var date = new Date(datetime).toJSON().slice(0, 19).replace(/T/, ' ')
      return date
    },
    completeOrder () {
      this.$router.go(-1)
    },
    closeOrderdetail () {
      this.$router.go(-1)
    },
    nextStep () {
      if (this.activeStep === 0) {
        this.checkBalance()
      } else if (this.activeStep === 1) {
        this.assignDriver()
      } else if (this.activeStep === 2) {
        this.driverOutByDriver()
      } else if (this.activeStep === 3) {
        this.driverInByDriver()
      } else if (this.activeStep === 4) {
        this.orderCompleteByDriver()
        console.log('...')
      } else if (this.activeStep === 5) {
        console.log('...')
      }
    },
    // prevStep () {
    //   if (this.activeStep === 4) {
    //     this.driverInByDriver()
    //   } else if (this.activeStep === 3) {
    //     this.driverOutByDriver()
    //   } else if (this.activeStep === 2) {
    //     this.assignDriver()
    //   }
    // },
    // prevStep (){
    //   if(this.activeStep === 0)
    // },
    getOrderData (id) {
      this.isLoading = true
      services.getOneOrder(id).then(response => {
        this.isLoading = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.selectedDriver = this.orderData.driver_name
          response.data.OrderPayments.forEach(el => {
            if (el.status === 'PAID') {
              this.paymentOrder = el
            }
          })
          this.calculateOrderStatus(response.data.order_status === 'Төлбөр төлөөгүй' || response.data.order_status === 'Төлбөр төлсөн' ? 'Үлдэгдэл шалгах' : response.data.driver_name && response.data.driver_name !== '' ? response.data.order_status : 'Жолоочид хуваарилах')
        } else {
          this.alertReporter('Алдаа', response.message, 'error')
          this.closeOrderdetail()
        }
      })
    },
    checkBalance () {
      this.isLoadingButton = true
      const query = '?order_id=' + this.orderData.id + '&user_name=' + this.userName
      services.checkBalance(query).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Үлдэгдэл шалгасан')
          this.alertReporter('Амжилттай', response.message, 'success')
          this.isLoadingButton = false
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
          this.isLoadingButton = false
        }
      })
    },
    assignDriver () {
      if (this.selectedDriver) {
        this.isLoadingButton = true
        const body = {
          order_id: this.orderData.id,
          user_name: this.userName,
          driver_name: this.selectedDriver
        }
        services.assignDriver(body).then(response => {
          this.isLoadingButton = false
          if (response.status === 'success') {
            this.orderData = response.data
            this.calculateOrderStatus(response.data.driver_name && response.data.driver_name !== '' ? response.data.order_status : 'Жолоочид хуваарилах')
            this.alertReporter('Амжилттай', response.message, 'success')
          } else {
            this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
          }
        })
      }
    },
    orderDelete (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.deleteLoading = true
          const body = {
            reason: this.cancelOrderForm.cancelStatus,
            desc: this.cancelOrderForm.desc
          }
          services.orderDelete(this.orderData.id, body).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.orderData.id} Захиалага амжилттай цуцлагдлаа`, 'success')
              this.deleteLoading = false
              this.loading = false
              this.orderCancel = false
              this.getOrderData(this.$route.params.id)
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа', `${this.orderData.id} Алдаа цуцлах боломжгүй захиалга байна.`, 'error')
              this.loading = false
              this.orderCancel = false
              this.deleteLoading = false
              this.getOrderData(this.$route.params.id)
            }
          })
        } else {
          return false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    async getRiderUser () {
      this.isLoading = true
      await services.getActiveUsers().then(response => {
        if (response.status === 'success') {
          this.paginationToken = response.data.PaginationToken
          this.count = response.data.Users.length
          this.riders = response.data.Users
        }
        this.isLoading = false
      })
    },
    driverOutByDriver () {
      this.isLoadingButton = true
      const body = {
        order_id: this.orderData.id,
        user_name: this.userName,
        driver_name: this.orderData.driver_name
      }
      services.driverOutByDriver(body).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Хүргэлтэнд гарсан')
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
        }
      })
    },
    driverInByDriver () {
      this.isLoadingButton = true
      const body = {
        order_id: this.orderData.id,
        user_name: this.userName,
        driver_name: this.orderData.driver_name
      }
      services.driverInByDriver(body).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Очсон')
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
        }
      })
    },
    orderCompleteByDriver () {
      this.isLoadingButton = true
      const body = {
        order_id: this.orderData.id,
        user_name: this.userName
      }
      services.orderCompleteByDriver(body).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Амжилттай')
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
        }
      })
    }
  }
}
</script>

<style>
  /* .color: {
    background-color: green;
  } */

/* .el-input__inner {
  background-color: rgb(40,52,67)!important;
  border: solid rgb(40,52,67) 1px;
  color: white;
} */

</style>
